<template>
  <div class="luoLa">
    <div class = "filterBar flexboxb">
      <div class = "flexboxs">
        <div style="margin-bottom: 20px; color: #999">上位一体机：</div>
        <div class = "filterItem">
          <Select class = "selectMid" :value="filter.aioGuid" placeholder="选择设备" label-in-value @on-change="({value})=>{filerChange(value, 'aioGuid', 'select')}" @on-clear="()=>{filterClear('aioGuid', 'select')}" filterable>
            <Option v-for="(item, index) in ytjList" :value="item.value" :key="index">{{ item.label }}</Option>
          </Select>
        </div>
      </div>
      <div v-if="accessData.boardAdd">
        <span class = "btnMy" @click="llNew">添加板卡</span>
      </div>
    </div>
    <div class="llCfgGrid">
      <Table :loading="listLoading" border :columns="listCol" :data="listData" height = "500"></Table>
    </div>
    <div class="pageBar">
        <Page :total="page.total" :current="page.pageNo" :page-size ="page.pageSize" show-elevator show-total @on-change="pageChange"></Page>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LuolaCfg',
  components: {
  },
  props: {
    farmOptions: Array,
    aioGuid: String,
    ytjList: Array,
  },
  data () {
    return {
      page: {
        total: 0,
        pageNo: 1,
        totalPage: 0,
        pageSize: 10,
      },
      filter: {},
      listData: [],
      listLoading: false,
      editData: {},
      deletingId: '',
      deleteLoading: false,
      statusLoading: false,
      statusId: '',
      listCol: [
        /* {
          title: '所属设备',
          key: 'aioName',
          minWidth: 100,
          render: (c, {row}) => {
            return c('span', row.isE ? this.getYtjName() : row.aioName);
          }
        }, */
        {
          title: '板卡名称',
          key: 'name',
          minWidth: 100,
          render: (m, {row}) => {
            let c = this.$createElement;
            return !row.isE ? 
              c('span', row.name) :
                c('Input', {
                  attrs: {
                    // value: this.newData.name
                  },
                  'class': {
                    noPass: row.name ? false : true,
                  },
                  props: {
                    value: row.name,
                  },
                  ref: 'llnameInput',
                  on: {
                    'on-change': (e) => {
                      // row.name = e.target.value;
                      if (!row.guid) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          name: e.target.value
                        });
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            name: e.target.value
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.llnameInput.focus();
                      })
                    }
                  }
                })
          }
        },
        {
          title: 'guid',
          key: 'guid',
          minWidth: 100,
          render: (c, {row}) => {
            return row.isE ? c('span', '--') : c('span', row.guid);
          }
        },
        {
          title: 'DO通道数',
          key: 'doCount',
          minWidth: 90,
          render: (m, {row}) => {
            let c = this.$createElement;
            return !row.isE ? 
              c('span', row.doCount) :
                c('div', {
                  'class': {
                    noPass: row.doCount || row.doCount === 0 ? false : true,
                  },
                }, [c('InputNumber', {
                  attrs: {
                    // value: this.newData.name
                  },
                  props: {
                    value: row.doCount,
                    min: 0,
                    step: 1,

                  },
                  ref: 'lldoCountInput',
                  on: {
                    'on-change': (val) => {
                      // row.name = e.target.value;
                      if (!row.guid) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          doCount: val
                        });
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            doCount: val
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.lldoCountInput.$el.children[1].children[0].focus();
                      })
                    }
                  }
                })]);
          }
        },
        {
          title: '板卡KEY',
          key: 'deviceKey',
          minWidth: 100,
          render: (m, {row}) => {
            let c = this.$createElement;
            return !row.isE ? 
              c('span', row.deviceKey) :
                c('Input', {
                  attrs: {
                    // value: this.newData.name
                  },
                  'class': {
                    noPass: row.deviceKey ? false : true,
                  },
                  props: {
                    value: row.deviceKey,
                  },
                  ref: 'llkeyInput',
                  on: {
                    'on-change': (e) => {
                      // row.name = e.target.value;
                      if (!row.guid) {
                        this.listData.splice(0, 1, {
                          ...this.listData[0],
                          deviceKey: e.target.value
                        });
                      } else {
                        for (var i = 0; i < this.listData.length; i++) {
                          this.listData[i].guid === row.guid && this.listData.splice(i, 1, {
                            ...this.listData[i],
                            deviceKey: e.target.value
                          })
                        }
                      }
                      this.$nextTick(() => {
                        this.$refs.llkeyInput.focus();
                      })
                    }
                  }
                })
          }
        },
        {
          title: '连接状态',
          key: 'clientStatusName',
          minWidth: 100,
          render: (c, {row}) => {
            return row.isE ? c('span', '--') : c('span', row.clientStatusName);
          }
        },
        {
          title: '板卡状态',
          key: 'statusName',
          minWidth: 100,
          render: (c, {row}) => {
            if (row.isE) return c('span', '--');
            return this.statusLoading && this.statusId === row.guid ? c('Spin', {
              props: {
                size: 'small'
              }
            }) : c('Select', {
              props: {
                value: +row.status,
                size: 'small'
              },
              on: {
                'on-change': (val) => {this.statusChange(val, row.guid)}
              }
            }, [
              c('Option', {
                props: {
                  value: 0
                }
              }, '未启用'),
              c('Option', {
                props: {
                  value: 1
                }
              }, '启用'),
              c('Option', {
                props: {
                  value: 2
                }
              }, '停用'),
            ])
          }
        },
        {
          title: '备注',
          key: 'remark',
          minWidth: 100,
          render: (c, {row}) => {
            return !row.isE ? 
              c('span', row.remark) :
                c('Input', {
                  attrs: {
                    // value: this.newData.name
                  },
                  props: {
                    value: row.remark,
                  },
                  on: {
                    'on-change': (e) => {
                      row.remark = e.target.value
                    }
                  }
                })
          }
        },
        {
          title: '操作',
          key: 'action',
          minWidth: 110,
          render: (c, {row}) => {
            if (row.isE) {
              return c('div', [
                c('Icon', {
                  attrs: {
                    title: '保存'
                  },
                  style: {
                    marginRight: '15px',
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-checkmark'
                  },
                  nativeOn: {
                    click: () => {
                      !row.guid ? this.saveLlNew(row) : this.saveLlEdit(row);
                    }
                  }
                }),
                c('Icon', {
                  attrs: {
                    title: '取消'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                  },
                  props: {
                    type: 'md-close'
                  },
                  nativeOn: {
                    click: () => {
                      !row.guid ? this.cancelLlNew(row) : this.cancelLlEdit(row)
                    }
                  }
                }),
              ])
            } else {
              let delBtn = c('Icon', {
                attrs: {
                  title: '删除'
                },
                style: {
                  cursor: 'pointer',
                  color: '#08DDDD',
                  fontSize: '16px',
                },
                props: {
                  type: 'md-trash'
                }
              });
              return this.deleteLoading && this.deletingId === row.guid ? c('Spin', {
                props: {
                  size: 'small'
                }
              }) : c('div', [
                this.accessData.boardMod && c('Icon', {
                  attrs: {
                    title: '编辑'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                    marginRight: '10px'
                  },
                  props: {
                    type: 'ios-brush'
                  },
                  nativeOn: {
                    click: () => {
                      this.llEdit(row)
                    }
                  }
                }),
                /* this.accessData.boardAisleList && c('Icon', {
                  attrs: {
                    title: '通道绑定'
                  },
                  style: {
                    cursor: 'pointer',
                    color: '#08DDDD',
                    fontSize: '16px',
                    marginRight: '10px'
                  },
                  props: {
                    type: 'md-git-compare'
                  },
                  nativeOn: {
                    click: () => {
                      this.aisleSet(row)
                    }
                  }
                }), */
                this.accessData.boardDel && c('Poptip', {
                  props: {
                    confirm: true,
                    title: '确认删除？',
                    placement: 'left'
                  },
                  on: {
                    'on-ok': () => {
                      this.llDel(row.guid)
                    },
                    'on-cancel': () => {

                    }
                  }
                }, [delBtn])
              ])
            }
          }
        }
      ],

    }
  },
  computed: {
    accessData () {
      return {
        boardAdd: this.$store.state.app.permissionsGroup.boardAdd,
        boardMod: this.$store.state.app.permissionsGroup.boardMod,
        boardDel: this.$store.state.app.permissionsGroup.boardDel,
        boardAisleList: this.$store.state.app.permissionsGroup.boardAisleList,
      }
    }
  },

  methods: {
    getList (loadingShow) {
      loadingShow && (this.listLoading = true);
      this.$post(this.$api.DEVICES.LUOLA_LIST, {
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
        ...this.filter,
      }).then(resp => {
        this.listData = resp.list.map(item => {
          return {
            ...item,
            farmId: +item.farmId,
          }
        });
        this.page = {
          total: +resp.total,
          pageNo: +resp.pageNo,
          totalPage: +resp.totalPage,
          pageSize: +resp.pageSize,
        }
        loadingShow && (this.listLoading = false);
      }).catch(e => {
        loadingShow && (this.listLoading = false);
      })
    },

    llNew () {
      if (this.listData[0] && this.listData[0].isE) return;
      this.listData.unshift({
        isE: true,
        aoCount: 0,
        aiCount: 0,
        diCount: 0,
        doCount: 0,
      })
    },

    filerChange (value, field, type) {
      if (type === 'select') {
        this.filter = {
          ...this.filter,
          [field]: value,
        };
        this.getList(true)
      }
    },

    filterClear (field, type) {
      if (type === 'select') {
        this.filter[field] && delete this.filter[field];
        this.getList(true);
      }
    },

    pageChange (pageNo) {
      this.page.pageNo = pageNo
      this.getList(true)
    },

    getYtjName () {
      let name = ''
      this.ytjList.forEach(item => {
        item.value === this.filter.aioGuid && (name = item.label);
      });
      return name;
    },

    
    llEdit (record) {
      let listData = this.listData.map(item => {
        return item.guid === record.guid ? {...item, isE: true} : item
      })
      this.listData = listData;
      this.editData = {...record}
    },

    saveLlEdit (record) {
      let noPass = !record.name 
        || !record.deviceKey 
          || !(record.doCount || record.doCount === 0 );
      if (noPass) return;
      this.editLoading = true;
      this.editId = record.guid;
      this.$post(this.$api.DEVICES.LUOLA_EDIT, {
        name: record.name,
        deviceKey: record.deviceKey,
        doCount: record.doCount,
        guid: record.guid,
        aioGuid: record.aioGuid,
        remark: record.remark,
      }).then(resp => {
        this.editLoading = false;
        this.getList(true);
      }).catch(e => {
        this.editLoading = false;
      })
    },

    cancelLlEdit (record) {
      this.listData = this.listData.map(item => {
        return item.guid === record.guid ? this.editData : item
      });
    },

    llNew () {
      if (this.listData[0] && this.listData[0].isE) return;
      this.listData.unshift({
        isE: true,
        doCount: 0,
      })
    },

    saveLlNew (record) {
      let noPass = !record.name 
        || !record.deviceKey 
          || !(record.doCount || record.doCount === 0 );
      if (noPass) return;
      this.listLoading = true;
      delete record.isE;
      this.$post(this.$api.DEVICES.LUOLA_NEW, {
        ...record,
        companyNo1: localStorage.companyNo,
        companyName: '',
        aioGuid: this.filter.aioGuid,
      }).then(resp => {
        this.getList(true)
      }).catch(e => {
        this.listLoading = false;
      })

    },

    cancelLlNew () {
      this.listData.shift()
    },

    statusChange (status, guid) {
      this.statusLoading = true;
      this.statusId = guid;
      this.$post(this.$api.DEVICES.LUOLA_STATUS_CHANGE, {
        status,
        guid,
      }).then(resp => {
        this.statusLoading = false;
        this.getList();
      }).catch(e => {
        this.statusLoading = false;
      })
    },

    llDel (guid) {
      this.deletingId = guid;
      this.deleteLoading = true;
      this.$post(this.$api.DEVICES.LUOLA_DEL, {
        guid
      }).then(resp => {
        this.deleteLoading = false;
        this.getList(true)
      }).catch(e => {
        this.deleteLoading = false;
      })
    }
  },

  mounted () {
    if (this.aioGuid) {
      this.filter = {aioGuid: this.aioGuid}
      this.getList(true);
    }
  },
  watch: {
    aioGuid: {
      handler (n, o) {
        !o && (this.filter = {aioGuid: this.aioGuid})
        this.getList(true);
      }
    }
  }
}
</script>
<style lang="less" scoped>
.luoLa {

}
</style>


